import React from "react";
import { Switch, Route } from "react-router-dom";

import Home from '../../pages/home';
import Merchant from '../../pages/merchant';
import Food from '../../pages/food';

export default function Router() {
    return (
        <Switch>
            <Route exact path='/' component={Home} />
            <Route path='/merchant/:merchantId' component={Merchant} />
            <Route path='/food/:foodId' component={Food} />
        </Switch>
    )
}