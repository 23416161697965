import React, {useEffect} from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, SimpleGrid, Heading, Image, Center } from '@chakra-ui/react';
import FoodItem from '../common/components/foodItem';
import { setFoods } from '../redux/actions/foodActions';
import Loader from '../common/components/loader';
import ApiConfig from '../api/apiConfig';
import merchantDefault from '../assets/images/merchant-default.jpg';


export default function Merchant() {
    const dispatch = useDispatch();
    const [loader, showLoader, hideLoader] = Loader();
    const {merchantId} = useParams();

    const merchants = useSelector((state)=>state.allMerchants.merchants)

    const renderList = merchants.map((merchant) => {
        const {id, name} = merchant;
        if(id == merchantId){
            return(
                <Heading as="h1" size="xl" color="white" key={id}>Menu {name}</Heading>
            )
        }
    })

    const fetchFoods = async () => {
        try{
            showLoader();
            let baseUrl = process.env.REACT_APP_API_URL;
            
            const response = await axios
                .get(baseUrl + `/get_foods?id_merchant=${merchantId}`, ApiConfig())
                .catch((err) => {
                    console.log("Err", err);
                });
            hideLoader();
            dispatch(setFoods(response.data));

        }catch(error){
            console.log(error)
        }
    }
    
    useEffect(() => {
        fetchFoods();

    },[]);

    
    return (
        <>
        <Center>
            <Image src={merchantDefault}
                h={{base:'180', lg:'480'}}
                w={{base:'100%', md:'lg', lg:'7xl'}}
                objectFit="cover"
                borderRadius={{base:'none', md:'lg'}}/>
        </Center>
        <Container 
        maxW={{base:"md", md:"lg", lg:"7xl" }}
        mt={{base:'-16', lg:'-32'}}
        mb={{base:'16', lg:'32'}}
        centerContent>
            {renderList}
        </Container>
        {loader}
        <Container
        maxW={{base:"md", md:"lg", lg:"7xl" }}
        mb="8">
            <SimpleGrid columns={{base:1, lg:3}} spacing={4}>
                <FoodItem/>
            </SimpleGrid>

        </Container>
        </>
    )
}