import React from "react";
import { HStack, Text } from '@chakra-ui/react'
import { TiThumbsUp, TiThumbsOk, TiThumbsDown } from 'react-icons/ti'

export default function LabelText(props){
    switch (props.label){
        case "sangat baik":
            return(
                <HStack bg="green.500" p="2" w="fit-content" borderRadius="xl">
                    <TiThumbsUp color="white" size="1.5em" />
                    <Text color="white" fontSize="sm">Hidangan Sehat</Text>
                </HStack>
            )
        case "baik":
            return(
                <HStack bg="orange.400" p="2" w="fit-content" borderRadius="xl">
                    <TiThumbsOk color="white" size="1.5em" />
                    <Text color="white" fontSize="sm">Dikonsumsi secara hati-hati</Text>
                </HStack>
            )
        case "buruk":
            return(
                <HStack bg="red.500" p="2" w="fit-content" borderRadius="xl">
                    <TiThumbsDown color="white" size="1.5em" />
                    <Text color="white" fontSize="sm">Sesekali Saja</Text>
                </HStack>
            )
        default:
            return(
                <div></div>
            )
    }
}