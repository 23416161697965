import React from 'react';
import {Container, SimpleGrid, Heading } from '@chakra-ui/react';
import MerchantItem from '../common/components/merchantItem';

export default function Home() {

    return (
        <>
        <Container 
        maxW={{base:"md", md:"lg", lg:"7xl" }}
        py={{base:"8", lg:"24"}}
        centerContent>
            <Heading as="h1" size="xl">Menu</Heading>
        </Container>
        <Container
        maxW={{base:"md", md:"lg", lg:"7xl" }}>
            <SimpleGrid columns={{base:1, lg:2}} spacing={8}>
                <MerchantItem/>
            </SimpleGrid>

        </Container>
        </>
    )
}