import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Heading, Image, Flex, Text, VStack, HStack } from '@chakra-ui/react';
import {ArrowForwardIcon} from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import Label from './label';

export default function FoodItem() {
    const foods = useSelector((state) => state.allFoods.foods);
    
    const renderList = foods.map((food) => {
        const {id, name, image, price, label } = food;
        return(
            <Box as="button" bg="gray.100" height="120px" borderRadius="lg" key={id}>
                <Link to={`/food/${id}`}>
            <Flex>
                <Box w="120px">
                    <Image src={image}
                    h={{base:'120', lg:'120'}}
                    w='120'
                    objectFit="cover"
                    borderRadius="lg"/>
                </Box>
                <VStack p="4" alignItems="left" flex="1" height="120px">
                    <Flex>
                        <VStack flex="1" alignItems="left" mr="2">
                            <Heading as="h3" size="sm" textAlign="left">
                                {name}
                            </Heading>
                            <Text textAlign="left" fontWeight="medium">
                                {price}
                            </Text>
                        </VStack>
                        <Label label={label} />

                    </Flex>
                    <HStack justifyContent="right">
                        <Text fontSize="12" textAlign="right">Nilai Gizi</Text><ArrowForwardIcon/>
                    </HStack>
                </VStack>
            </Flex>
            </Link>
        </Box>
        )
    })
    return(
        <>{renderList}</>
    )
}