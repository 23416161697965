import {ActionTypes} from '../contants/actionTypes';

const initialState = {
    nutritions: []
}

export const nutritionReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SET_NUTRITIONS:
            return {...state, nutritions: payload.data};
        default:
            return state;
    }
}