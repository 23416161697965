import { ActionTypes } from '../contants/actionTypes';

export const setFoods = (foods) => {
    return {
        type: ActionTypes.SET_FOODS,
        payload: foods
    }
}

export const selectedFoods = (food) => {
    return {
        type: ActionTypes.SELECTED_FOOD,
        payload: food
    }
}