import {ActionTypes} from '../contants/actionTypes';

const initialState = {
    foods: [],
    nutritions: []
}

export const foodReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SET_FOODS:
            return {...state, foods: payload.data};
        case ActionTypes.SELECTED_FOOD:
            return {...state, nutritions: payload.data};
        default:
            return state;
    }
}