import {ActionTypes} from '../contants/actionTypes';

const initialState = {
    auth: {"id_user": 1,
    "name": "Super Admin",
    "email": "superadmin@admin.com",
    "access_token": "0TbkyTD7V6KHIFzN",
    "expiry": 1634912313}
}

export const authReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SET_AUTH:
            return {...state, auth: payload.data};
        default:
            return state;
    }
}