import React from 'react';
import { Box, Heading, Image } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import merchantDefault from '../../assets/images/merchant-default.jpg';


export default function MerchantItem() {
    const merchants = useSelector((state) => state.allMerchants.merchants);
    
    const renderList = merchants.map((merchant) => {
        const {id, name } = merchant;
        return(
                <Box as="button" bg="tomato" height="auto" borderRadius="lg" boxShadow="lg" key={id}>
                    <Link to={`/merchant/${id}`}>
                    <Image src={merchantDefault}
                    h={{base:'160', lg:'240'}}
                    w='100%'
                    objectFit="cover"
                    borderRadius="lg"/>
                    <Heading as="h3" size="md" p="4">
                        {name}
                    </Heading>
                    </Link>
                </Box>
        )
    })

    return(
        <>{renderList}</>
    )
}