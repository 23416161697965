import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Image, Heading, Text, Button, Center, HStack } from '@chakra-ui/react';
import {ArrowBackIcon} from '@chakra-ui/icons';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import Nutrition from '../common/components/nutrition';
import LabelText from '../common/components/labelText';


export default function Food() {
    let history = useHistory();
    const {foodId} = useParams();

    const foods = useSelector((state) => state.allFoods.foods);
    
    const findById = (foodId) => {
        const array = foods;
        for (let i = 0; i < array.length; i++){
            if (array[i].id == foodId){
                return (array[i])
            }
        }
    }

    const selectedFood = findById(foodId);
    const {name,price,image,label} = selectedFood;

    return (
        <>
        <Container 
        maxW={{base:"none", md:"none", lg:"2xl" }}
        py={{base:"0", lg:"4"}}
        px="0"
        centerContent>
            <Image src={image}
            w="100%"
            maxH={{base:"xs", md:"md"}}
            borderRadius={{base:0, lg:"lg"}}
            objectFit="cover"
            />
        </Container>
        <Container
        maxW={{base:"none", md:"xl", lg:"2xl" }}
        p="4"
        mt={{base:"0", md:"4"}}
        bgColor="white"
        borderRadius={{base:0, md:"lg"}}
        >
            <Heading as="h2" size="lg">{name}</Heading>
            <Text py="4">{price}</Text>
            <LabelText label={label} />
        </Container>
        <Container
        maxW={{base:"none", md:"xl", lg:"2xl" }}
        py="8"
        mt={{base:"0", md:"4"}}
        bgColor="white"
        borderRadius={{base:0, md:"lg"}}
        h="max"
        >
            <Heading as="h3" size="md" pb={{base:"4"}}>Nilai Gizi</Heading>
            <Nutrition foodId={foodId}/>
                
        </Container>
        <Center py="4">
            <Button bg="white" onClick={()=> history.goBack()}>
                <HStack>
                    <ArrowBackIcon/>
                    <Text>Kembali</Text>
                </HStack>
            </Button>

        </Center>
        </>
    )
}