export const ActionTypes = {
    SET_MERCHANTS : "SET_MERCHANTS",
    SELECTED_MERCHANT : "SELECTED_MERCHANT",
    REMOVE_SELECTED_MERCHANT : "REMOVE_SELECTED_MERCHANT",
    
    SET_FOODS : "SET_FOODS",
    SELECTED_FOOD : "SELECTED_FOOD",
    REMOVE_SELECTED_FOOD : "REMOVE_SELECTED_FOOD",

    SET_NUTRITIONS : "SET_NUTRITIONS",

    SET_AUTH : "SET_AUTH"
}
