import React, {useEffect, useState} from 'react';
import axios from 'axios';
import ApiConfig from '../../api/apiConfig';
import { Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import Loader from './loader';

export default function Nutrition(props) {
    const nutritions = useSelector((state)=>state.allNutritions.nutritions)
    const [nuts, setNuts] = useState([]);
    const [loader, showLoader, hideLoader] = Loader();


    const fetchNutritions = async () => {
        showLoader()
        try{
            let baseUrl = process.env.REACT_APP_API_URL;
        
        const response = await axios
            .get(baseUrl + `/get_detail_food?id_food=${ props.foodId }`, ApiConfig())
            .catch((err) => {
                console.log("Err", err);
            });
        hideLoader()
        setNuts(response.data.data)
    } catch(error){
        console.log(error)
    }
        
    }

    useEffect(() => {
        fetchNutritions();
    },[]);

    const renderList = nuts.map((nutrition) => {
        const {id, value, id_nutrition } = nutrition;

        const name = nutritions.map((nutrition) => {
            const {id, name} = nutrition;
            if(id == id_nutrition){
                return(
                    name
                )
            }
        })
        const unit = nutritions.map((nutrition) => {
            const {id, unit} = nutrition;
            if(id == id_nutrition){
                return(
                    unit
                )
            }
        })
        
        return(
            
            <Tr key={id}>
            <Td>{name}</Td>
            <Td isNumeric>{ value }</Td>
            <Td>{unit}</Td>
            </Tr>
            
        )
    })

    return(
        <>
        <Table variant="striped" colorScheme="orange" size="sm">
            <Thead>
                <Tr>
                    <Th>Nutrisi</Th>
                    <Th isNumeric>Nilai</Th>
                    <Th>Satuan</Th>
                </Tr>
            </Thead>
            <Tbody>
                {renderList}
            </Tbody>
        </Table>
        {loader}
        </>
    )
}