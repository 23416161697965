import { ActionTypes } from '../contants/actionTypes';

export const setMerchants = (merchants) => {
    return {
        type: ActionTypes.SET_MERCHANTS,
        payload: merchants
    }
}

export const selectedtMerchants = (merchant) => {
    return {
        type: ActionTypes.SELECTED_MERCHANT,
        payload: merchant
    }
}