import { combineReducers } from 'redux';
import { merchantReducer } from './merchantReducer';
import { foodReducer } from './foodReducer';
import { nutritionReducer } from './nutritionReducer';
import { authReducer as auth } from './authReducer';

const reducers = combineReducers({
    allMerchants: merchantReducer,
    allFoods: foodReducer,
    allNutritions: nutritionReducer,
    auth
});

export default reducers;