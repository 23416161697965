import {ActionTypes} from '../contants/actionTypes';

const initialState = {
    merchants: []
}

export const merchantReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case ActionTypes.SET_MERCHANTS:
            return {...state, merchants: payload.data};
        default:
            return state;
    }
}