import React, { useState } from "react";
import { Spinner, Center } from "@chakra-ui/react";

export default function Loader() {
    const [loading, setLoading] = useState(false);
    return [
        loading ? <Center m="8"><Spinner size="xl"/></Center> : null,
        () => setLoading(true),
        () => setLoading(false)
    ];
}
