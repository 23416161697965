import Router from './common/router/router';
import axios from 'axios';
import ApiConfig from './api/apiConfig';
import { useDispatch } from 'react-redux';
import { setMerchants } from './redux/actions/merchantActions';
import { setNutritions } from './redux/actions/nutritionActions';
import { useEffect } from 'react';

function App() {
  const dispatch = useDispatch()

  const fetchMerchants = async () => {
    try{
        let baseUrl = process.env.REACT_APP_API_URL;
        
        const response = 
        await axios
        .get(baseUrl + "/get_merchants", ApiConfig())
        .catch((err) => {
            console.log("Err", err);
        });

        dispatch(setMerchants(response.data));
    }catch(error){
        console.log(error)
    }
  }
  const fetchNutritions = async () => {
    try{
        let baseUrl = process.env.REACT_APP_API_URL;
        
        const response = await axios
            .get(baseUrl + `/get_nutritions`, ApiConfig())
            .catch((err) => {
                console.log("Err", err);
            });
        dispatch(setNutritions(response.data));
    } catch(error) {
        console.log(error)
    }
}

  useEffect(() => {
    fetchMerchants();
    fetchNutritions();
}, []);

  return (
    <>
    <Router />
    </>
  );
}

export default App;
