import React from "react";
import { TiThumbsUp, TiThumbsOk, TiThumbsDown } from 'react-icons/ti'

export default function Label(props) {
    switch (props.label){
        case "sangat baik":
            return(
                <TiThumbsUp color="green" size="1.5em" />
            )
        case "baik":
            return(
                <TiThumbsOk color="orange" size="1.5em" />
            )
        case "buruk":
            return(
                <TiThumbsDown color="red" size="1.5em" />
            )
        default:
            return(
                <div></div>
            )
    }
}